import React from "react"
import { Link } from "gatsby"
const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem`, marginBottom: 0 }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default function Layout({ children }) {
  return (
    <div style={{ minHeight: `100vh` }}>
    <div style={{ backgroundColor: `rgb(171 171 171 / 80%)` }}>
    <div style={{ margin: `0rem auto`, maxWidth: 1000 }}>
      <header style={{ display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, alignItems: `center`}}>
        <Link to="./" style={{ textShadow: `none`, backgroundImage: `none` }}>
          <div className={"imageDefault"} style={{ display: `flex`, alignItems: `center`, margin: `1rem 0rem 0rem 0rem` }}>
             <img className={"imageResize"} src="/logo_transparent.png" alt="logotrans" />
           </div>
        </Link>
        <h3 className={"textResize"} style={{ display: `flex` }}>Crimble Consulting</h3>
        <ul className={"navDefault"} style={{ listStyle: `none` }}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/services/">Services</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </header>
    </div>
    </div>
    <div>
      <img src="/cropfive.png" alt="header" />
    </div>
    <div style={{ margin: `0rem auto`, maxWidth: 700, padding: `0 1rem` }}>
      {children}
    </div>
    <footer style={{ display: `flex`, backgroundColor: `rgb(171 171 171 / 80%)` }}>
      <ul style={{ margin: `1rem`, listStyle: `none`, textAlign: `left`}}>
      <li style={{ fontSize: 12 }}>Registered Company Nr: - 11736013</li>
      <li style={{ fontSize: 12 }}>Vat Nr: - 314 4414 37</li>
      <li style={{ fontSize: 12 }}>Registered Office Address: - 5-7 New Road Radcliffe, Manchester, United Kingdom, M26 1LS</li>
      </ul>
    </footer>
    </div>
  )
}
import React from "react"
import Layout from "../components/layout"

export default function About() {
  return (
    <Layout>
      <br></br>
      <h2>
        Services
      </h2>
      <div class="containermain">
        <div class="container">
          <div class="table">
            <div class="table-header">
              <h5>Commercial Services</h5>
            </div>
            <div class="table-contents">
              <div class="column">
                <div class="column-header">
                  <p class="column-header-text">Budgetary analysis and financial management of projects from conception to completion.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="table">
            <div class="table-header">
              <h5>PFI & FM</h5>
            </div>
            <div class="table-contents">
              <div class="column">
                <div class="column-header">
                  <p class="column-header-text">Whole life costing and building maintenance with the aim to maximise income and minimise expenditure.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="table">
            <div class="table-header">
              <h5>Lifecycle advice and costing</h5>
            </div>
            <div class="table-contents">
              <div class="column">
                <div class="column-header">
                  <p class="column-header-text">Cradle to grave cost analysis for your projects to provide the peace of mind that you have the optimal solution.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="table">
            <div class="table-header">
              <h5>Project management</h5>
            </div>
            <div class="table-contents">
              <div class="column">
                <div class="column-header">
                  <p class="column-header-text">Managing the full process from feasibility through procurement, contract, construction and completion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}